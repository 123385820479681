@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@400;600&display=swap");

@layer base {
  html {
    font-family: "manrope", sans-serif;
    @apply h-full;
  }

  html * {
    font-family: "inter", sans-serif;
  }

  body {
    @apply h-full dark:!bg-gray-800 !bg-gray-200 dark:text-white text-gray-200;
  }
}

@layer root {
  iframe#webpack-dev-server-client-overlay {
    display: none !important
  }
}